@tailwind base;
@tailwind components;
@tailwind utilities;


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}
.container {
  margin: 4px;
  overflow: hidden;
  position: fixed;
}

.container > div {
  padding: 1em;
}
.container {
  display: grid;
  width: 100%;
  grid-template-columns: 380px 400px 1fr;
  grid-auto-rows: minmax(100px, auto);
}

.container__graph {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  border: 1px solid black;
}

.container__graph-info {
  margin-left: 12px;
}

.btn-margin-left {
  margin-left: 4px;
}

.container__graph-area {
  max-height: 400px;
  border: 1px dotted gray;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 1;
  overflow: hidden;
}

.container__graph-data {
  grid-column: 1 / 1;
  grid-row: 2 / 3;
  margin-bottom: 4px;
  z-index: 2;
}

.container__graph-config {
  grid-column: 2 / 5;
  grid-row: 2 / 3;
  margin-bottom: 4px;
  z-index: 2;
}

.container__form {
  grid-column: 5/ 6;
  grid-row: 1 / 4;
  min-width: 400px;
  z-index: 3;
}

.cross-icon {
  padding-right: 8px;
}

.cross-icon:hover {
  cursor: pointer;
}

.form-wrapper {
  overflow-y: scroll;
  max-height: 70vh;
}

.json-data-container {
  height: 250px;
  min-width: 360px;
  overflow: scroll;
}

.submit-button {
  margin-top: 22px;
}

.reset-button {
  margin-top: 22px;
  margin-left: 8px;
}

.invisible-button {
  background: transparent;
  border: none !important;
  font-size: 0;
}

.tooltip-help {
  cursor: help;
}

.btn-clipboard {
  border: none;
  background-color: transparent;
  cursor: pointer;
  margin-left: 20px;
  user-select: none;
}

.btn-clipboard:hover {
  background-color: lightskyblue;
} */
